.App {
  text-align: center;
}

.App-header {
  background-color: #f8faff;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body{
  margin: 0;
  background-color: rgb(238, 238, 238);
  color: rgb(5, 5, 5);
  font-family: 'Do Hyeon', sans-serif;
}

body, html{
  height: 100%;
}

.navBar {
  margin: 0 auto;
  text-align: right;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  padding: 3px 0;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: rgb(11, 127, 148);
}

.navBar a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding: 0px 5px;

}
.navBar li {
  display: inline;
  margin-right: 15px;
}

.navBar a:hover {
  color: rgb(250,221,89);
}

.navHome {
  float: left;
  vertical-align: center;
}

.font{
  font-family: 'Do Hyeon', sans-serif;
}

.link-clean {
  color: black;
  text-decoration: none;
}

.d-block{
  display: block;
}

.f-vh {
  height: 100vh;
}

.bg-def {
  background-color: rgb(11, 127, 148);
}

.pt-5{
  padding-top: 5%; 
}
.pt-10 {
  padding-top: 10%;
}
.pt-20{
  padding-top: 20%;
}

.pt-30{
  padding-top: 30%;
}

.pb-20 {
  padding-bottom: 20%;
}

.spacing{
  padding: 5%;
}

.ta-l {
  text-align: left
}

.fw {
  width: 100%;
}
.fh {
  height: 100%;
}

.h-100{
  height: 500px;
}

.hp-name{
  font-size: 2rem;
  margin: 0;
}

.hp-title{
  font-family: 'lato', sans-serif;
  font-size: 2rem;
  margin: 0;
}
.techList{
  text-align: left;
  /* font-family: sans-serif; */
  list-style-type: none;
  padding: 0;
}
.techList li{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20%;
}
.mt-35 {
  margin-top: 35%
}

.mb-20 {
  margin-bottom: 20%;
}

.header-txt{
  font-size: 35px;
}

.proj-title{
  font-size: 20px;
  color: white;
  text-align: left;
}

.proj-img{
  height: 100%;
  width: 100%;
}

.imgLayer {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.textLayer {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

}

.projText {
  background-color: #5f3085;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.aboutText {
  padding: 10% 15%;
}

.cont:hover .imgLayer {
  opacity: 0.3;
}

.cont:hover .textLayer {
  opacity: 1;
}

.proj-btn {
  padding: 8px 16px;
  text-decoration: none;
  margin: 4px 4px;
  border-radius: 5px;
  font-family: lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
.l-btn{
  color: white;
  background-color: rgb(11, 127, 148);
  border: solid 1px rgb(11, 127, 148);
}
.l-btn:hover{
  color: white;
  background-color: rgb(5, 103, 121);
}
.r-btn{
  color: rgb(11, 127, 148);
  background-color: white;
  border: solid 3px rgb(11, 127, 148);

  border: solid 1px rgb(11, 127, 148);
}
.r-btn:hover{
  /* color: rgb(250,221,89); */
  color: white;
  background-color: rgb(11, 127, 148);
}

.tr-btn1 {
  color: white;
  background-color: rgb(132, 114, 143);
  border: solid 2px rgb(132, 114, 143);
}
.tr-btn2 {
  background-color: white;
  color: rgb(132, 114, 143);
  border: solid 2px rgb(132, 114, 143);
}

.tr-btn1:hover{
  color :white;
  background-color:rgb(119, 81, 143);;
  border: solid 2px rgb(119, 81, 143);;
}
.tr-btn2:hover{
  color: white;
  background-color: rgb(132, 114, 143);;
  border: solid 2px rgb(132, 114, 143);
}

.ds-btn:hover {
  color: white;
  background-color: rgb(57, 167, 224);
  font-weight: 700;
  border: solid 1px rgb(57, 167, 224); 
}
.bp-btn1{
  color: white;
  background-color: rgb(213,6,0);
}
.bp-btn1:hover{
  color: white;
  background-color: rgb(133, 15, 11);;
}
.bp-btn2{
  color: rgb(213,6,0);
  border: solid 1px rgb(213,6,0);
}

.bp-btn2:hover {
  color: white;
  background-color: rgb(213,6,0);
  font-weight: 700;
  border: solid 1px rgb(213,6,0);
}

.hero-image{
  width: 100%;
  margin-right: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dt9ukta15/image/upload/v1598414764/desk_br8x7y.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
